/* You can add global styles to this file, and also import other style files */



body, html {
  font-family: 'DM Sans Regular', sans-serif;
    /* height: 100%;
    background-color: #f8f8f8; */
}

.fontss{
  font-family: 'DM Sans Regular', sans-serif !important;
}

.label__error {
  text-align: start;
  color: #ff1e5a;
  font-size: 14px;
  font-family: 'DM Sans Regular', sans-serif;
}

@media only screen and (max-device-width: 480px) {
  .textFontMobile {
    font-size: 12px;
    line-height: 21px;
  }
  
  .HeadingFontMobile {
    font-size: 15px;
   
  }
}




.ppp {
  font-size: 17px;

}

/* spinner  */
.spinner_overlay {
  position: fixed;
  /* left: 0;
  top: 0; */
  margin-left: -9px;
  margin-top: -2px;
  width: 100%;
  height: 100%;
  background-color: #f6f6f6;
  /* opacity: 0.9; */
  z-index: 100;
}


/* spinner  */
.mainLoader {
  position: fixed;
  /* left: 0;
    top: 0; */
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: repeat;
  background-size: cover;
  background-image: url('./assets/images/mm.jpg');
  /* background-color: #f6f6f6; */
  /* opacity: 0.9; */
  z-index: 1000;
}


.trees {
  position: fixed;
  margin: auto;
  text-align: center;
  top: 188px;

  bottom: 0;
  left: 0;
  right: 0;
  z-index: 100;
}

.mainLoad {
  position: fixed;
  margin: auto;
  text-align: center;
  top: 190px;

  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1000;
}


.loader img {
  /* animation: spinlogo 1s linear infinite; */
  /* animation: bounce 1s linear infinite; */
  animation: zoom-in-zoom-out 1.2s ease infinite;
}

.gptLoader {
  animation: bounce 1s linear infinite; 
}

@-webkit-keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    -webkit-transform: translateY(0);
  }

  40% {
    -webkit-transform: translateY(-30px);
  }

  60% {
    -webkit-transform: translateY(-15px);
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-30px);
  }

  60% {
    transform: translateY(-15px);
  }
}



@keyframes spinlogo {
  0% {
    transform: rotate(360deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@page {
  size: auto !important;
}

@media print {
  @page {
    size: 20in 12in !important;
  }
}



.zoom-in-out-box {
  margin: 24px;
  width: 50px;
  height: 50px;
 
  animation: zoom-in-zoom-out 1.5s ease infinite;
}

@keyframes zoom-in-zoom-out {
  0% {
    /* transform: scale(0.5, 0.5); */
    transform: scale(0.5, 0.5);
  }
  50% {
    transform: scale(1, 1);
  }
  100% {
    /* transform: scale(0, 1); */
    transform: scale(1, 1);
  }
}



/* Add a CSS class for the tooltip */
.tooltip {
  position: relative;
  display: inline-block;
}

/* Style the tooltip text */
.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: #000; /* Black background color */
  color: #fff; /* White text color */
  text-align: center;
  border-radius: 6px;
  padding: 5px;
  position: absolute;
  z-index: 1;
  bottom: 125%; /* Position above the button */
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
  transition: opacity 0.3s;
}

/* Show the tooltip text on hover */
.tooltip:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}


